import Logo from "../assets/read_niche_banner.png"
import {useEffect, useState} from "react";
import {getContent} from "../api/api";
import Footer from "./Footer";
import Content from "./Content";
import ArticleMainPage from "./ArticleMainPage";
import TopMenu from "./TopMenu";
import Modal from "./Modal";
import '../styles/Homepage.css';

const Homepage = () => {

    const [content, setContent] = useState([]);
    const [lastAddedIds, setLastAddedIds] = useState([]);
    const [modal, setModal] = useState(true);


    const slicePost = function (data) {
        for (let i=0; i < Object.keys(data).length; i++){
            const index = Object.keys(data)[i];
            data[index].content = data[index].content.slice(0,150) + "[...]"
        }
        return data
    };

    // const sliceEndFromLastItem = function (data){
    //     let len = Object.keys(data).length;
    //     let lastAddedIds = [];
    //     for( let i=0 ;i < 9; i++ ){
    //         lastAddedIds.push(len-i);
    //     }
    //     return lastAddedIds;
    // }


    useEffect(()=>{
        (async ()=>{
           const data = await getContent();
           const newContent = slicePost(data);
           setContent(newContent);
            // setLastAddedIds(sliceEndFromLastItem(data));
            setLastAddedIds([1,2,3,4,5,6,7,8,9])
        })();
        setTimeout(()=>{
            setModal(false);
        } , 2000);
    },[]);

    const Search = () => {
        return (
            <div className="search-banner-container">
                <div>
                    <ul className="search-banner">
                        <header><span>SEARCH FOR</span><span>Sponsored</span></header>
                        <li><a className="search-banner-inner"
                               href="/red.html?r=https%3A%2F%2Fgosearch.io%2F%3Fvariant%3D10%26brand%3DC123675%26afsterm%3DLife%20Insurance%20Online"
                               target="_blank">Life Insurance Online</a></li>
                        <li><a className="search-banner-inner"
                               href="/red.html?r=https%3A%2F%2Fgosearch.io%2F%3Fvariant%3D10%26brand%3DC123675%26afsterm%3DLose%20Weight%20Now"
                               target="_blank">Lose Weight Now</a></li>
                        <li><a className="search-banner-inner"
                               href="/red.html?r=https%3A%2F%2Fgosearch.io%2F%3Fvariant%3D10%26brand%3DC123675%26afsterm%3DHome%20Loan%20Refinance"
                               target="_blank">Home Loan Refinance</a></li>
                        <li><a className="search-banner-inner"
                               href="/red.html?r=https%3A%2F%2Fgosearch.io%2F%3Fvariant%3D10%26brand%3DC123675%26afsterm%3DLosing%20Belly%20Fat%20Exercises%20For%20Women"
                               target="_blank">Losing Belly Fat Exercises For Women</a></li>
                        <li><a className="search-banner-inner"
                               href="/red.html?r=https%3A%2F%2Fgosearch.io%2F%3Fvariant%3D10%26brand%3DC123675%26afsterm%3DVacation%20Packages"
                               target="_blank">Vacation Packages</a></li>
                        <li><a className="search-banner-inner"
                               href="/red.html?r=https%3A%2F%2Fgosearch.io%2F%3Fvariant%3D10%26brand%3DC123675%26afsterm%3DLife%20Insurance"
                               target="_blank">Life Insurance</a></li>
                        <li><a className="search-banner-inner"
                               href="/red.html?r=https%3A%2F%2Fgosearch.io%2F%3Fvariant%3D10%26brand%3DC123675%26afsterm%3DCheap%20Hotels"
                               target="_blank">Cheap Hotels</a></li>
                        <li><a className="search-banner-inner"
                               href="/red.html?r=https%3A%2F%2Fgosearch.io%2F%3Fvariant%3D10%26brand%3DC123675%26afsterm%3DBelly%20Wrap%20For%20Weight%20Loss"
                               target="_blank">Belly Wrap For Weight Loss</a></li>
                    </ul>
                </div>
            </div>
        )

    }

    return (
        <div>
            <TopMenu back_image={Logo} content={content}/>
            <Search/>
            {modal? ( <Modal/> ) :
                (<Content>
                    { Object.keys(content).reverse().map(function (key) {
                        if (lastAddedIds.includes(parseInt(key))){
                            return <ArticleMainPage key={key} id={content[key].id} category={content[key].category} title={content[key].title} content={content[key].content} image={content[key].image}/>
                        }
                    })
                    }
                </Content>)}
            <Footer/>
        </div>
    );
}


export default Homepage